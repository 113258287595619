/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "gatsby";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        {/*
        <Button
          href="/gatsby-finwe-fi/landing-page/"
          color="transparent"
          target="_self"
          className={classes.navLink}
        >
          Home
        </Button>
        */}
        <Link className={classes.navLink} to="/">Home</Link>
      </ListItem>

{/*       <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Solutions"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          //buttonIcon={Apps}
          dropdownList={[
            <Link to="/" className={classes.dropdownLink}>
              Video Production
            </Link>,
            <Link to="/" className={classes.dropdownLink}>
              Video Stitching
            </Link>,
            <Link to="/" className={classes.dropdownLink}>
              Video Streaming
            </Link>,
            <Link to="/" className={classes.dropdownLink}>
              Video Playback
            </Link>,
            <Link to="/" className={classes.dropdownLink}>
              Video Analytics
            </Link>,
            <Link to="/" className={classes.dropdownLink}>
              VR Video
            </Link>,
            <Link to="/" className={classes.dropdownLink}>
              Custom Apps
            </Link>,
          ]}
        />
      </ListItem>
 
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Products"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          //buttonIcon={Apps}
          dropdownList={[
            <Link to="/" className={classes.dropdownLink}>
              360° Video Starter Kit
            </Link>,
            <Link to="/" className={classes.dropdownLink}>
              LiveSYNC Presentation Solution
            </Link>,
            <Link to="/" className={classes.dropdownLink}>
              Orion360 SDK
            </Link>,
          ]}
        />
      </ListItem>
*/}      
      <ListItem className={classes.listItem}>
        {/*
        <Button
          href="/gatsby-finwe-fi/contact-page/"
          color="transparent"
          target="_self"
          className={classes.navLink}
        >
          Contact
        </Button>
        */}
        <Link className={classes.navLink} to="/contact-page/">Contact</Link>
      </ListItem>
{/*
      <ListItem className={classes.listItem}>
        <Button
          href="https://www.google.com"
          color="transparent"
          target="_self"
          className={classes.navLink}
        >
          About Us
        </Button>
      </ListItem>
*/}      
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
